import { AuthorizeBox } from '~/components/authorize/authorize-box';
import { Outlet, useLocation } from '@remix-run/react';
import { TrailLinesContainer } from '~/components/authorize/trail-lines-container';

export default function Authorize() {
  const location = useLocation();
  const isForgotPassphrase = location.pathname === '/app/authorize/forgot-passphrase';

  return (
    <div
      className="relative flex h-full w-full text-center"
      style={{ background: 'linear-gradient(0deg, #35007F 0%, #4000BF 50%, #35007F 60.81%, #000000 93.75%)' }}
    >
      <TrailLinesContainer>
        <div className="flex h-full flex-col items-center justify-center">
          <AuthorizeBox width={isForgotPassphrase ? 770 : 373}>
            <Outlet />
          </AuthorizeBox>
        </div>
      </TrailLinesContainer>
    </div>
  );
}
